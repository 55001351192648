import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Swal from "sweetalert";
import { v4 as uuidv4 } from 'uuid';
import HtmlDiff from 'htmldiff-js';
import { Editor } from "@tinymce/tinymce-react";
import { useSearchParams, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ButtonGroup, Col, Dropdown, ListGroup, Row, ToggleButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from 'react-icons/md';
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import { FcCheckmark } from "react-icons/fc";
import { FaFilter } from "react-icons/fa";
import { BiCommentAdd, BiCommentDetail } from "react-icons/bi";
import { LuHistory } from "react-icons/lu";
import { TbRestore } from "react-icons/tb";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";

const ReviewArticleOrPostDetails = () => {
  const dispatch = useDispatch();
  const { type, articleId } = useParams();

  const editorRef = useRef(null);
  const editorDiffRef = useRef(null);
  const textareaRef = useRef(null);

  const [searchParams] = useSearchParams();
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [articleType] = useState(type && type !== "" ? type : null);
  const [articleSubType] = useState(searchParams && searchParams.get('sub-type') ? searchParams.get('sub-type') : null);

  const [editorText, setEditorText] = useState("");
  const [oldArticleText, setOldArticleText] = useState("");
  const [diffArticleText, setDiffArticleText] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [commentModal, setCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [versionHistory, setVersionHistory] = useState([]);
  const [replyCmtText, setReplyCmtText] = useState("");
  const [selectedCommentId, setSelectedCommentId] = useState("");
  const [selectedVersionItem, setSelectedVersionItem] = useState({});
  const [currentStatus, setCurrentStatus] = useState("");
  const [historyTabValue, setHistoryTabValue] = useState("comments");
  const [editorKey, setEditorKey] = useState(0);

  const editorInitObj = {
    height: "75vh",
    menubar: false,
    plugins: "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks " +
      "code fullscreen selection insertdatetime media table paste code help wordcount ",
    toolbar: "blocks | bold italic backcolor | undo redo alignleft aligncenter",
    content_style: `html { position: absolute } body { margin: 24px; font-family: 'Jost', sans-serif; font-size: 14px }
      .selected-wrapper { background: #fef2cd } .selected-wrapper.active { background: #f7b803 }
      ${articleType === "Social Media Post Writer" ? 'p { margin: 0; }' : ''}`,
  };

  useEffect(() => {
    if (articleType && articleType !== "" && articleSubType && articleSubType !== "") {
      handleGetAllCommentListById('open', 'true');

      if (articleType.indexOf("+") !== -1 || articleSubType.indexOf("+") !== -1) {
        const tempUrl = `${window.location.pathname}${window.location.search}`;
        window.location.href = tempUrl.replaceAll("+", " ");
      }
    }
  }, []);

  const handleAddEventOnIframe = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (document.querySelector(".tox-edit-area") && editorRef && editorRef.current) {
        const iframe = document.querySelector(".tox-edit-area").querySelector("iframe");
        if (iframe && iframe.contentWindow && iframe.contentWindow.document && editorRef.current.selection) {
          const iframeDoc = iframe.contentWindow;
          iframeDoc.document.addEventListener("selectionchange", () => {
            const selection = iframeDoc.getSelection();
            if (selection && selection.toString().trim() !== "") {
              const selectedTextStr = editorRef.current.selection.getContent();
              if (selectedTextStr && selectedTextStr.indexOf("<span") === -1) {
                setSelectedText(selection.toString());
              } else {
                setSelectedText("");
              }
            } else {
              handleCloseAddCommentModal();
            }
          });
        }
      }
    } catch (error) {
    }
  }

  const handleGetAllCommentListById = (status, checkStatus = 'false') => {
    setCommentList([]);
    setCurrentStatus(status);
    dispatch(setLoader(true));

    const queryParams = `status=${status}&articleType=${articleType}&articleSubType=${articleSubType}&checkReview=${checkStatus}`;
    const url = `${apiBaseUrl}/api/editorial-comment/list/byArticle/${articleId}?${queryParams}`;
    AxiosApi.get(url).then(async (response) => {
      if (response && response.data) {
        if (response.data.data && response.data.data.length > 0) {
          setCommentList(response.data.data);
        }

        if (response.data.articleText && response.data.articleText !== "") {
          let htmlText = response.data.articleText;
          if (status === "resolve" || status === "deleted") {
            htmlText = await removeSpanTags(htmlText);
          }

          if (response.data.articleText && response.data.articleText !== "" && /<html[^>]*>/.test(response.data.articleText)) {
            const parser = new DOMParser();
            const bodyText = parser.parseFromString(htmlText, 'text/html').body.outerHTML;
            htmlText = `${response.data.articleText.substring(0, response.data.articleText.indexOf("<body"))} ${bodyText} </html>`
              .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
          }

          setEditorText(htmlText);
          setOldArticleText(htmlText);
          setEditorKey(editorKey + 1);
        }

        setTimeout(() => {
          handleAddEventOnIframe();
        }, 1000);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  }

  const handleGetAllVersionHistory = () => {
    setVersionHistory([]);
    dispatch(setLoader(true));

    const queryParams = `articleType=${articleType}&articleSubType=${articleSubType}`;
    const url = `${apiBaseUrl}/api/editorial-version/history/byArticle/${articleId}?${queryParams}`;
    AxiosApi.get(url).then((response) => {
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        setVersionHistory(response.data.data);
        handleSelectedCurrentVersionItem(response.data.data[0]);
      } else if (response && response.data && response.data.articleText && response.data.articleText !== "") {
        setDiffArticleText(response.data.articleText);
      }

      if (response && response.data && response.data.articleText && response.data.articleText !== "") {
        setEditorText(response.data.articleText);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  }

  const handleAddNewComment = async () => {
    const commentId = uuidv4().split("-")[0];
    if (editorRef && editorRef.current) {
      dispatch(setLoader(true));
      const htmlText = editorRef.current.getContent();
      let selectedTextStr = editorRef.current.selection.getContent();
      selectedTextStr = selectedTextStr.replace(/<([^>\s]+)[^>]*>(?:\s*(?:<br \/>|&nbsp;|&thinsp;|&ensp;|&emsp;|&#8201;|&#8194;|&#8195;)\s*)*<\/\1>/gm, "");
      let highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, commentId);

      if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
        const parser = new DOMParser();
        const bodyText = parser.parseFromString(highlightedContent, 'text/html').body.outerHTML;
        highlightedContent = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
          .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
      }

      const obj = {
        commentId: commentId,
        comment: commentText,
        selectedText: selectedTextStr,
        user_info: JSON.stringify({
          fullName: `${loginDetails?.first_name || ""} ${loginDetails?.last_name || ""}`,
          userName: loginDetails?.username || "",
          profileUrl: loginDetails?.profile_url || "",
        }),
        isResolved: false,
        createAt: new Date(),
      }

      try {
        const dataObj = {
          ...obj, articleType, articleSubType,
          articleId: articleId,
          articleText: highlightedContent,
        };
        await AxiosApi.post(`${apiBaseUrl}/api/editorial-comment/create-comment`, dataObj);
        dispatch(setLoader(false));

        setEditorText(highlightedContent);
        setCommentList((prev) => ([...prev, obj]));
        handleCloseAddCommentModal();

        setTimeout(() => {
          handleAddEventOnIframe();
        }, 100);
      } catch (error) {
        toast.error("Something went wrong.");
        dispatch(setLoader(false));
      }
    }
  }

  const handleAddCommentReply = async () => {
    const replyId = uuidv4().split("-")[0];
    const obj = {
      commentId: replyId,
      parentCommentId: selectedCommentId,
      comment: replyCmtText,
      senderType: "admin",
      isResolved: false,
      createAt: new Date(),
    }

    const tempObj = [...commentList];
    const index = tempObj.findIndex((ele) => ele.commentId === selectedCommentId);
    if (index !== -1) {
      const replyCmtArray = tempObj[index].replyComments || [];
      replyCmtArray.push(obj);
      tempObj[index].replyComments = [...replyCmtArray];

      if (currentStatus === "resolve") {
        const htmlText = editorRef.current.getContent();
        const selectedTextStr = tempObj[index].selectedText;
        let highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, selectedCommentId);
        if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
          const parser = new DOMParser();
          const bodyText = parser.parseFromString(highlightedContent, 'text/html').body.outerHTML;
          highlightedContent = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
            .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
        }

        setEditorText(highlightedContent);
        obj.articleText = highlightedContent;
      }
    }

    try {
      dispatch(setLoader(true));
      await AxiosApi.post(`${apiBaseUrl}/api/editorial-comment/reply-comment/${selectedCommentId}`, obj);
      dispatch(setLoader(false));
      setCommentList([...tempObj]);
      setReplyCmtText("");

      if (currentStatus === "resolve") {
        handleGetAllCommentListById('open');
      }
    } catch (error) {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    }
  }

  const handleResolvedOrDeleteComment = async (commentId, type) => {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, ${type} it!` },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        const htmlText = editorRef.current.getContent();
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlText;

        const spanElements = tempDiv.querySelectorAll(`span[data-group="${commentId}"]`);
        spanElements.forEach(spanElement => {
          const innerText = spanElement.innerText;
          spanElement.outerHTML = innerText;
        });

        let modifiedHTML = tempDiv.innerHTML;
        if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
          const parser = new DOMParser();
          const bodyText = parser.parseFromString(modifiedHTML, 'text/html').body.outerHTML;
          modifiedHTML = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
            .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
        }

        const tempObj = commentList.filter((ele) => ele.commentId !== commentId);

        try {
          dispatch(setLoader(true));
          const obj = { type, articleText: modifiedHTML, userName: loginDetails?.username };
          await AxiosApi.post(`${apiBaseUrl}/api/editorial-comment/resolve-or-delete-comment/${commentId}`, obj);
          dispatch(setLoader(false));

          setEditorText(modifiedHTML);
          setCommentList([...tempObj]);
        } catch (error) {
          toast.error("Something went wrong.");
          dispatch(setLoader(false));
        }
      }
    });
  }

  const handleCloseAddCommentModal = () => {
    setSelectedText("");
    setCommentText("");
    setCommentModal(false);

    if (document.querySelector(".tox-edit-area")) {
      const iframe = document.querySelector(".tox-edit-area").querySelector("iframe");
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const selection = iframe.contentWindow.getSelection();
        if (selection && selection.toString().trim() !== "") {
          selection.removeAllRanges();
        }
      }
    }
  }

  const handleSelectOrRemoveEditorComment = async (e) => {
    const iframe = document.querySelector('iframe');
    const getId = e.target.getAttribute('data-group');
    if (e.target.tagName === 'SPAN' && getId && getId !== "") {
      setSelectedCommentId(getId);
      await new Promise(resolve => setTimeout(resolve, 200));
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        const activeSpans = iframe.contentWindow.document.querySelectorAll('span.active');
        activeSpans.forEach((span) => { span.classList.remove("active") });

        const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${getId}"]`);
        elements.forEach(element => { element.classList.add("active") });
      }
    } else if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      handleCloseSelectedComment();
    }
  }

  const handleOnSelectCommentById = async (e, commentId) => {
    e.stopPropagation();
    setSelectedCommentId(commentId);
    const iframe = document.querySelector('iframe');
    await new Promise(resolve => setTimeout(resolve, 200));
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const activeSpans = iframe.contentWindow.document.querySelectorAll('span.active');
      activeSpans.forEach((span) => { span.classList.remove("active") });

      const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${commentId}"]`);
      elements.forEach(element => { element.classList.add("active") });

      if (elements.length === 0) {
        const index = commentList.findIndex((ele) => ele.commentId === commentId);
        if (index !== -1) {
          let htmlText = editorRef.current.getContent();
          if (currentStatus === "resolve" || currentStatus === "deleted") {
            htmlText = await removeSpanTags(htmlText);
          }

          const selectedTextStr = commentList[index]?.selectedText ? commentList[index].selectedText : "";
          const highlightedContent = await handleHighLightSelectedEditorText(htmlText, selectedTextStr, commentId);
          setEditorText(highlightedContent);
          handleScrollOnTextEditorByTag(`[data-group="${commentId}"]`);

          setTimeout(() => {
            const elements = iframe.contentWindow.document.querySelectorAll(`[data-group="${commentId}"]`);
            elements.forEach(element => { element.classList.add("active") });
          }, 500);
        }
      } else {
        handleScrollOnTextEditorByTag(`[data-group="${commentId}"]`);
      }
    }
  }

  const handleScrollOnTextEditorByTag = async (selection) => {
    await new Promise(resolve => setTimeout(resolve, 200));
    const iframe = document.querySelector('iframe');
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const elements = iframe.contentWindow.document.querySelectorAll(selection);
      if (elements && elements.length > 0) {
        elements[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  }

  const handleRemoveFirstAndLastElement = async (string, type) => {
    return new Promise((resolve, reject) => {
      if (type === "start") {
        if (string[0] === "<") {
          const elementPattern = '<[^>]+>[A-Za-z0-9]';
          const regexPattern = new RegExp(elementPattern, 'i');
          const result = string.match(regexPattern);
          if (result && result.length > 0) {
            const matchStr = result[0];
            string = string.replace(matchStr, matchStr[matchStr.length - 1]);
          }
          return handleRemoveFirstAndLastElement(string, type).then(resolve).catch(reject);
        } else {
          resolve(string);
        }
      } else {
        if (string[string.length - 1] === ">") {
          const regex = /<[^>]*>$/;
          string = string.replace(regex, '');
          return handleRemoveFirstAndLastElement(string, type).then(resolve).catch(reject);
        } else {
          resolve(string);
        }
      }
    });
  }

  const handleHighLightSelectedEditorText = async (htmlText, selectedTextStr, commentId) => {
    let selectedTempTextStr = await handleRemoveFirstAndLastElement(selectedTextStr, "start");
    selectedTempTextStr = await handleRemoveFirstAndLastElement(selectedTempTextStr, "end");

    return new Promise((resolve, reject) => {
      const div = document.createElement('div');
      div.innerHTML = selectedTextStr;
      const elements = div.querySelectorAll('*');
      if (elements && elements.length > 0) {
        elements.forEach(element => {
          const span = document.createElement('span');
          span.innerHTML = element.innerHTML;
          span.classList.add("selected-wrapper");
          span.setAttribute("data-group", commentId);
          element.innerHTML = '';
          element.appendChild(span);
        });

        let modifiedHTML = div.innerHTML;
        modifiedHTML = modifiedHTML.replace(/^(<(?!span\s)[^>]*>)+/g, '');
        modifiedHTML = modifiedHTML.replace(/(<\/((?!span).)*>$)/g, '');

        const highlightedContent = htmlText.replace(selectedTempTextStr, modifiedHTML);
        resolve(highlightedContent);
      } else {
        const highlightedContent = htmlText.replace(selectedTextStr, `<span class="selected-wrapper" data-group="${commentId}">${selectedTextStr}</span>`);
        resolve(highlightedContent);
      }
    });
  }

  const handleCloseSelectedComment = async () => {
    setSelectedCommentId("");
    setReplyCmtText("");

    const iframe = document.querySelector('iframe');
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const activeSpans = iframe.contentWindow.document.querySelectorAll('span.active');
      activeSpans.forEach((span) => { span.classList.remove("active") });

      if (currentStatus === "resolve" || currentStatus === "deleted") {
        const htmlText = editorRef.current.getContent();
        const modifiedHTML = await removeSpanTags(htmlText);
        setEditorText(modifiedHTML);
      }
    }
  }

  const handleOnChangeArticleHistoryTab = (event) => {
    const value = event.currentTarget.value;
    setHistoryTabValue(value);

    setCommentList([]);
    setVersionHistory([]);
    setEditorText("");
    setOldArticleText("");
    setDiffArticleText("");
    setSelectedCommentId("");
    setSelectedVersionItem({});

    if (value === "version") {
      handleGetAllVersionHistory();
    } else {
      handleGetAllCommentListById('open');
    }
  }

  const handleSaveArticleTextAndNewVersion = async () => {
    if (oldArticleText === editorText || currentStatus === "resolve" || currentStatus === "deleted") {
      return false;
    }

    try {
      dispatch(setLoader(true));

      let modifiedEditorText = editorText;
      if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
        const parser = new DOMParser();
        const bodyText = parser.parseFromString(modifiedEditorText, 'text/html').body.outerHTML;
        modifiedEditorText = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
          .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
      }

      const dataObj = {
        userName: loginDetails?.username,
        articleType: articleType,
        articleSubType: articleSubType,
        articleId: articleId,
        oldArticle: oldArticleText,
        newArticle: modifiedEditorText,
        isSentMailToUser: true,
      };
      await AxiosApi.post(`${apiBaseUrl}/api/editorial-version/save-text/add-version`, dataObj);
      dispatch(setLoader(false));
      setOldArticleText(modifiedEditorText);
      setEditorText(modifiedEditorText);
      setEditorKey(editorKey + 1);

      setTimeout(() => {
        handleAddEventOnIframe();
      }, 100);
    } catch (error) {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    }
  }

  const removeSpanTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const spans = doc.querySelectorAll('span');
    spans.forEach((span) => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span);
      }
      parent.removeChild(span);
    });
    return doc.body.innerHTML;
  };

  const handleSelectedCurrentVersionItem = async (item) => {
    setSelectedVersionItem(item);
    if (selectedVersionItem.id === item.id) {
      return false;
    }

    const oldHtmlString = await removeSpanTags(item.old_article);
    const newHtmlString = await removeSpanTags(item.new_article);

    let modifiedHTML = await HtmlDiff.execute(oldHtmlString, newHtmlString);
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">&nbsp;</ins>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">`, `<ins style="color: #406619; background-color: #eaf2c2;  text-decoration: none;">`);
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">&nbsp;</ins>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">`, `<ins style="color: #406619; background-color: #eaf2c2;  text-decoration: none;">`);

    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">&nbsp;</del>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">`, `<del style="color: #b30000; background-color: #fadad7; text-decoration: line-through;">`);
    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">&nbsp;</del>`, "");
    modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">`, `<del style="color: #b30000; background-color: #fadad7; text-decoration: line-through;">`);

    setDiffArticleText(modifiedHTML);
    handleScrollOnTextEditorByTag(`span`);
  }

  const handleRestoreVersionAndSetText = async (item) => {
    if (item.is_restored === 1) {
      Swal("Oops...", "Unable to restore version. The requested version has already been restored.", "error");
      return false;
    }

    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: `Yes, restore it!` },
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          dispatch(setLoader(true));

          let modifiedEditorText = editorText;
          if (oldArticleText && oldArticleText !== "" && /<html[^>]*>/.test(oldArticleText)) {
            const parser = new DOMParser();
            const bodyText = parser.parseFromString(modifiedEditorText, 'text/html').body.outerHTML;
            modifiedEditorText = `${oldArticleText.substring(0, oldArticleText.indexOf("<body"))} ${bodyText} </html>`
              .replaceAll("\n", " ").replaceAll("\t", " ").replace(/\s+/g, ' ').trim();
          }

          const dataObj = {
            userName: loginDetails?.username,
            articleType: articleType,
            articleSubType: articleSubType,
            articleId: articleId,
            oldArticle: modifiedEditorText,
            newArticle: item.old_article,
            isRestore: true,
            restoreVersion: item.name,
          };
          await AxiosApi.post(`${apiBaseUrl}/api/editorial-version/save-text/add-version`, dataObj);
          Swal("Restored!", "Version has been restored.", "success");
          dispatch(setLoader(false));
          handleGetAllVersionHistory();
        } catch (error) {
          toast.error("Something went wrong.");
          dispatch(setLoader(false));
        }
      }
    });
  }

  return (
    <div className="w-100">
      <div className="border-radius-12 bg-white AIWriterBlog">
        <div className="AIWriterBlogHead pb-2">
          <div className="AIWriterBlogHeadTop">
            <h4>Review Article</h4>
          </div>
        </div>
        <div className="addllyFormWrap">
          <div className="smartWriterTab">
            <Row className="review-article-wrapper">
              <Col sm={8}>
                {historyTabValue === "version" ? (
                  <div className="position-relative difference-editor-content form-group">
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                      onInit={(evt, editor) => (editorDiffRef.current = editor)}
                      value={diffArticleText} disabled key={diffArticleText} init={{
                        height: "75vh", menubar: false, toolbar: "",
                        content_style: `${(editorText.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")}
                          body { margin: 24px; font-family: 'Jost', sans-serif; font-size: 14px }
                          ${articleType === "Social Media Post Writer" ? 'p { margin: 0; }' : ''}`,
                      }}
                    />
                  </div>
                ) : (
                  <div className="position-relative review-editor-content form-group">
                    <div className="d-md-flex justify-content-between align-items-center text-end text-md-start">
                      <button type="button" className="content-view-btn addlly-primary"
                        disabled={((selectedText && selectedText !== "") && currentStatus !== "resolve") ? false : true}
                        onClick={() => {
                          setCommentModal(true);
                          setTimeout(() => {
                            if (textareaRef && textareaRef.current) {
                              textareaRef.current.focus();
                            }
                          }, 1000);
                        }}
                      >
                        <BiCommentAdd className="fs-5" /> <span>Add Comment</span>
                      </button>
                      <button type="button" className="images-view-btn addlly-primary"
                        disabled={(oldArticleText === editorText || currentStatus === "resolve" || currentStatus === "deleted") ? true : false}
                        onClick={handleSaveArticleTextAndNewVersion}
                      >
                        <MdEdit className="fs-5" /> <span>Update Article</span>
                      </button>
                    </div>
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                      onInit={(evt, editor) => (editorRef.current = editor)} key={editorKey}
                      value={editorText} onEditorChange={(e) => setEditorText(e)}
                      init={{
                        ...editorInitObj, setup: function (editor) { editor.on('click', (e) => handleSelectOrRemoveEditorComment(e)); },
                        content_style: `${(oldArticleText.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")} ${editorInitObj.content_style}`,
                      }}
                    />
                  </div>
                )}
              </Col>

              <Col sm={4} onClick={handleCloseSelectedComment} className="">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <ButtonGroup className="">
                    <ToggleButton
                      id={`comments`} type="radio" variant="outline-primary" name="historyButton" className="px-3"
                      value={"comments"} checked={historyTabValue === "comments"} onChange={handleOnChangeArticleHistoryTab}
                      data-tooltip-id="my-tooltip" data-tooltip-content="Comments" data-tooltip-place="bottom"
                    >
                      <BiCommentDetail className="fs-4" />
                    </ToggleButton>
                    <ToggleButton
                      id={`version`} type="radio" variant="outline-primary" name="historyButton" className="px-3"
                      value={"version"} checked={historyTabValue === "version"} onChange={handleOnChangeArticleHistoryTab}
                      data-tooltip-id="my-tooltip" data-tooltip-content="Version History" data-tooltip-place="bottom"
                    >
                      <LuHistory className="fs-4" />
                    </ToggleButton>
                  </ButtonGroup>
                  {historyTabValue === "comments" && (
                    <Dropdown data-bs-theme="dark">
                      <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary" className="">
                        <FaFilter /> <span className="mx-2">Filter: {currentStatus === "resolve" ? "Resolved" :
                          currentStatus === "deleted" ? "Deleted" : "Open"}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {[
                          { label: "Open", value: "open" },
                          { label: "Resolved", value: "resolve" },
                          { label: "Deleted", value: "deleted" },
                        ].map((item, index) => (
                          <Dropdown.Item as="button" key={index}
                            onClick={() => handleGetAllCommentListById(item.value)}
                            active={item.value === currentStatus ? true : false}
                          >
                            {item.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <hr />

                {historyTabValue === "version" ? (
                  <div className="sidebar-version-wrapper">
                    {versionHistory && versionHistory.length > 0 ? (
                      <ListGroup>
                        {versionHistory.map((version, index) => (
                          <ListGroup.Item
                            className={`version-content ${selectedVersionItem.id === version.id ? "active" : ""}`}
                            onClick={() => handleSelectedCurrentVersionItem(version)} key={index}
                          >
                            <div>Version: {version?.name || "-"}</div>
                            <p>{moment(version.created_at).format("MMMM DD, hh:mm A")}</p>
                            <p className="m-0">{version?.user_name || "-"}</p>

                            {version?.name?.indexOf("Restore") === -1 && (
                              <button className="btn btn-light restore-btn" type="button" onClick={() => handleRestoreVersionAndSetText(version)}
                                data-tooltip-id="my-tooltip" data-tooltip-content="Restore Version" data-tooltip-place="bottom"
                              >
                                <TbRestore className="fs-5 w-auto m-0" />
                              </button>
                            )}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    ) : (
                      <NoDataAvailable />
                    )}
                  </div>
                ) : (
                  <div className="sidebar-comment-wrapper py-2 ps-4 pe-2">
                    {commentModal && (
                      <div className="comment-form-wrapper mb-4">
                        <div className="user-info d-flex align-items-center mb-3">
                          {loginDetails?.profile_url ? (
                            <img src={loginDetails.profile_url} alt="user" className="rounded-circle userIcon" />
                          ) : (
                            <div className="avatar text-white">{loginDetails?.username[0]}</div>
                          )}
                          <div className="ms-2">
                            <p className="mb-0">
                              {loginDetails.first_name && loginDetails.last_name ? `${loginDetails.first_name} ${loginDetails.last_name}` :
                                loginDetails.first_name ? loginDetails.first_name : loginDetails.last_name ? loginDetails.last_name : loginDetails.username}
                            </p>
                          </div>
                        </div>
                        <div className="addllyFormWrap">
                          <div className="form-group mb-2">
                            <textarea
                              name="comment" type="text" rows="3" placeholder="Add Comment"
                              className={`addllyForm-control rounded-3 px-3`} value={commentText}
                              onChange={(e) => setCommentText(e.target.value)} ref={textareaRef}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button className="addlly-outline py-2 me-3" type="button" onClick={handleCloseAddCommentModal}>
                              Close
                            </button>
                            <button className="addlly-primary py-2" type="button" onClick={handleAddNewComment}
                              disabled={commentText && commentText !== "" ? false : true}
                            >
                              Comment
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {commentList && commentList.length > 0 ? commentList.map((comment, index) => (
                      <CommentReplyListComponent
                        comment={comment} selectedCommentId={selectedCommentId} key={index}
                        handleOnSelectCommentById={handleOnSelectCommentById}
                        handleAddCommentReply={handleAddCommentReply} currentStatus={currentStatus}
                        replyCmtText={replyCmtText} setReplyCmtText={setReplyCmtText}
                        handleResolvedOrDeleteComment={handleResolvedOrDeleteComment}
                        userRes={comment?.user_info ? comment.user_info : {}}
                      />
                    )) : !commentModal ? (
                      <NoDataAvailable isNoCommentImg={true} />
                    ) : null}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

const CommentReplyListComponent = ({
  comment, selectedCommentId, handleOnSelectCommentById, handleAddCommentReply,
  replyCmtText, setReplyCmtText, handleResolvedOrDeleteComment, userRes: propUserRes, currentStatus
}) => {
  const replyTextRef = useRef(null);
  const [userRes, setUserRes] = useState({});

  useEffect(() => {
    try {
      const tempUserRes = JSON.parse(propUserRes);
      setUserRes(tempUserRes);
    } catch (error) {
    }
  }, [])

  return (
    <div className={`comment-form-history mb-3 ${selectedCommentId === comment.commentId ? "active-comment" : ""}`}
      onClick={(e) => handleOnSelectCommentById(e, comment.commentId)} onKeyDown={() => { }}
    >
      <div className="user-info d-flex align-items-center mb-2">
        {userRes?.profileUrl ? (
          <img src={userRes.profileUrl} alt="user" className="rounded-circle userIcon" />
        ) : (
          <div className="avatar text-white">{userRes?.userName?.[0]}</div>
        )}
        <div className="ms-2">
          <p className="mb-0">{userRes?.fullName || userRes?.userName}</p>
          <p className="mb-0">{moment(comment.createAt).format("MMM DD - hh:mm A")}</p>
        </div>

        {currentStatus === "open" && (
          <div className="d-flex align-items-center ms-auto" onClick={(e) => e.stopPropagation()} onKeyDown={() => { }}>
            <button className="btn btn-light resolve-btn me-2" type="button"
              onClick={() => handleResolvedOrDeleteComment(comment.commentId, "resolve")}
              data-tooltip-id="my-tooltip" data-tooltip-content="Mark as resolved" data-tooltip-place="bottom"
            >
              <FcCheckmark className="fs-5 w-auto m-0" />
            </button>
            <Dropdown onClick={(e) => e.stopPropagation()}>
              <Dropdown.Toggle id="option-dropdown" drop={'start'} className="option-dropdown-wrapper">
                <button className="btn btn-light option-btn" type="button"
                  data-tooltip-id="my-tooltip" data-tooltip-content="More Options.." data-tooltip-place="bottom"
                >
                  <BsThreeDotsVertical className="fs-5 w-auto m-0" />
                </button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleResolvedOrDeleteComment(comment.commentId, "delete")}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="addllyFormWrap comment-content">
        <pre className="fw-normal comment-div">{comment.comment}</pre>

        {comment.replyComments && comment.replyComments.length > 0 && (
          <React.Fragment>
            <hr />
            {selectedCommentId === comment.commentId ? (
              <div className="messages">
                {comment.replyComments.map((message, index) => (
                  <div key={index} className={`message ${message.senderType}`}>
                    <pre className="">{message.comment}</pre>
                    <p className="">{moment(message.createAt).format("hh:mm A")}</p>
                  </div>
                ))}

                {currentStatus === "deleted" && comment.deletedAt && comment.deletedAt !== "" && (
                  <div className={`message resolve mt-4`}>
                    <pre className="">Deleted At</pre>
                    <p className="">{moment(comment.deletedAt).format("MMMM DD, hh:mm A")}</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <button className="btn text-primary p-0 d-flex align-items-center" type="button">
                  <HiPlus className="me-1" /> View Reply's
                </button>
              </div>
            )}
          </React.Fragment>
        )}

        {selectedCommentId === comment.commentId && currentStatus !== "deleted" && (
          <div className="form-group mt-3">
            <textarea
              name="reply" type="text" rows="1" className={`addllyForm-control rounded-3 p-2 bg-white`}
              value={replyCmtText} onChange={(e) => setReplyCmtText(e.target.value)} ref={replyTextRef}
              placeholder={`${currentStatus === "resolve" ? "Adding a comment will re-open this discussion..." : "Add Reply"}`}
            />
            {replyCmtText && replyCmtText !== "" && (
              <div className="d-flex justify-content-start mt-1">
                <button className="addlly-primary py-2 me-2" type="button" onClick={handleAddCommentReply}>
                  Reply
                </button>
                <button className="addlly-outline py-2" type="button" onClick={() => setReplyCmtText("")}>
                  Clear
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ReviewArticleOrPostDetails;
