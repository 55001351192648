import React, { useState, useEffect, useRef } from "react";
import { Accordion, Col, Row, Tab, Tabs, ListGroup, Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import HtmlViewer from "../Components/HtmlViewer";
import CodeEditor from "../Components/CodeEditor";
import { AxiosApi } from "../utility/axios";
import { FacebookPostPreview, FactCheckerPreview, InstagramPostPreview, LinkedInPostPreview, RenderBarChart, TwitterPostPreview } from "../Components/PostPreview";
import SeoScore from "../Components/SeoScore";
import MediaLibraryImagesModal from "../Components/MediaLibraryModal";
import RefundRequestCredits from "../Components/RefundRequestCredits";

const editorInitObj = {
    height: "calc(100% - 32px)",
    menubar: false,
    plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
        "undo redo | blocks | " +
        "bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
    content_style: "body { margin:24px; font-family: 'Jost', sans-serif; font-size:14px }",
};

const RysenseBlogDetails = () => {

    const editorRef = useRef(null);
    const params = useParams();

    const [toggle, setToggle] = useState(false);
    const [toggle1, setToggle1] = useState(false);
    const [articleData, setArticleData] = useState({});
    const [postImagesModal, setPostImagesModal] = useState({ open: false });
    const [showModal, setShowModal] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

    useEffect(() => {
        if (params?.id) {
            AxiosApi.get(`${apiBaseUrl}/api/article/history-or-details/byType/get-rysense-blog-byId?id=${params?.id}`).then((dd) => {
                setArticleData(dd.data);
            }).catch((dd) => {
                alert(dd);
            });
        }
    }, [params]);

    const handleViewAiImagesById = (subType) => {
        const obj = {
            type: "1-Click Blog", subType,
            articleId: params?.id ? params.id : "",
            userId: articleData?.user_id ? articleData.user_id : "",
        };

        setPostImagesModal({ open: true, isLoader: true });
        AxiosApi.get(`${apiBaseUrl}/api/admin/media-library-image/get-images?${new URLSearchParams(obj).toString()}`).then((res) => {
            setTimeout(() => {
                setPostImagesModal({ open: true, isLoader: false, ...res.data });
            }, 1000);
        }).catch((error) => {
            setPostImagesModal({ open: false, isLoader: false });
            toast.error("Something went wrong, Please try again.");
        });
    };

    const handleShowModal = (index) => {
        setCurrentImageIndex(index);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDownload = (link) => {
        const a = document.createElement("a");
        a.href = link;
        a.download = link.split("/").pop(); // Extract filename from the URL
        a.click();
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex - 1 + articleData.editedAllImages.length) % articleData.editedAllImages.length
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex + 1) % articleData.editedAllImages.length
        );
    };


    return (
        <div className="w-100">
            <div className="border-radius-12 bg-white AIWriterBlog">
                <div className="AIWriterBlogHead">
                    <div className="AIWriterBlogHeadTop">
                        <h3>Happy Dot Blog Writer</h3>
                    </div>
                </div>
                <div className="addllyFormWrap">
                    <form>
                        <Row>
                            <Col xs="6">
                                <div className="form-group mb-4">
                                    <label>Uploaded File Link</label>
                                    <input
                                        name="topic" type="text" placeholder="Topic" defaultValue={articleData?.csvLink}
                                        className={`addllyForm-control`} disabled
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group mb-4">
                                    <label>Keyword if any</label>
                                    <input
                                        name="keywords" type="text" placeholder="Type keywords"
                                        defaultValue={articleData?.keyword} className={`addllyForm-control`} disabled
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group mb-4">
                                    <label>Blog Type</label>
                                    <input
                                        name="generatedTopic" type="text" placeholder="Type Topic Generated"
                                        defaultValue={articleData?.blogType} className={`addllyForm-control`} disabled
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group mb-4">
                                    <label>Meta title Generated</label>
                                    <input
                                        name="generatedMetaTitle" type="text" placeholder="Type Meta title Generated"
                                        defaultValue={articleData?.meta_title} className={`addllyForm-control`} disabled
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="form-group mb-4">
                                    <label>Meta Description Generated</label>
                                    <textarea
                                        name="generatedMetaDescription" type="text" placeholder="Type Meta Description Generated"
                                        defaultValue={articleData?.meta_dec} className={`addllyForm-control`} disabled
                                    />
                                </div>
                            </Col>
                        </Row>
                        {articleData.blogType === "Short Blog" ? (
                            <Row>
                                <Col sm="12">
                                    <div className="d-flex gap-2 align-items-center mb-2">
                                        <label className="fw-semibold">Happy Dot Short Blog Steps</label>
                                    </div>
                                    <Accordion>
                                        {articleData.insightData && (
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="">Selected Insights - {1 < articleData.currentStep ? "Done" : "Pending"}</Accordion.Header>
                                                <Accordion.Body>
                                                    {(
                                                        articleData.insightData &&
                                                        Object.keys(articleData.insightData)?.length > 0
                                                    ) && Object.keys(articleData.insightData)?.filter((item) => item).map((item, i) => (

                                                        <ul className="list-unstyled setOfOutline" key={i}>
                                                            <li key={i}>
                                                                <div style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                        <div className="d-flex align-items-center justify-content-between w-100">
                                                                            <h4 className="fw-normal">{articleData.insightData[item].Theme}</h4>
                                                                        </div>
                                                                    </div>
                                                                    {articleData.insightData[item].SubThemes && articleData.insightData[item].SubThemes?.map((it, ind) => (
                                                                        <div key={ind} className="checkListItem py-2">
                                                                            <div className="d-flex w-100 flex-column">
                                                                                <Form.Check type="checkbox" className="custom-checkbox"
                                                                                    checked={articleData.selectedSubThemes ? (articleData.selectedSubThemes?.find((item1) => (item1 === it.Question)) ? true : false) : false}
                                                                                />
                                                                                <span>Question</span>
                                                                                <input className="w-100" style={{ border: "1px solid black", borderRadius: "5px", background: "white" }} value={it.Question} placeholder="Type here outline" type="text" disabled />
                                                                                <span>Answer</span>
                                                                                <input className="w-100" style={{ border: "1px solid black", borderRadius: "5px", background: "white" }} value={it.Answer} placeholder="Type here outline" type="text" disabled />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )}
                                    </Accordion>
                                </Col>
                            </Row>
                        ) :
                            (
                                <Row>
                                    <Col sm="12">
                                        <div className="d-flex gap-2 align-items-center mb-2">
                                            <label className="fw-semibold">Happy Dot Long Blog Steps</label>
                                        </div>
                                        <Accordion>
                                            {articleData.insightData && (
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className="">Select Insights - {1 < articleData.currentStep ? "Done" : "Pending"}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {(
                                                            articleData.insightData &&
                                                            Object.keys(articleData.insightData)?.length > 0
                                                        ) && Object.keys(articleData.insightData)?.filter((item) => item).map((item, i) => (

                                                            <ul className="list-unstyled setOfOutline" key={i}>
                                                                <li key={i}>
                                                                    <div style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                                                                        <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                                                <h4 className="fw-normal">{articleData.insightData[item].Theme}</h4>
                                                                            </div>
                                                                        </div>
                                                                        {articleData.insightData[item].SubThemes && articleData.insightData[item].SubThemes?.map((it, ind) => (
                                                                            <div key={ind} className="checkListItem py-2">
                                                                                <div className="d-flex w-100 flex-column">
                                                                                    <Form.Check type="checkbox" className="custom-checkbox"
                                                                                        checked={articleData.selectedSubThemes ? (articleData.selectedSubThemes?.find((item1) => (item1 === it.Question)) ? true : false) : false}
                                                                                    />
                                                                                    <span>Question</span>
                                                                                    <input className="w-100" style={{ border: "1px solid black", borderRadius: "5px", background: "white" }} value={it.Question} placeholder="Type here outline" type="text" disabled />
                                                                                    <span>Answer</span>
                                                                                    <input className="w-100" style={{ border: "1px solid black", borderRadius: "5px", background: "white" }} value={it.Answer} placeholder="Type here outline" type="text" disabled />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {articleData.topicDesc && (
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className="">Describe Details - {2 < articleData.currentStep ? "Done" : "Pending"}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="d-flex flex-column gap-4">
                                                            <div style={{ border: "1px solid var(--Dark-dark_5)", borderRadius: "5px", padding: "10px", background: "var(--Dark-dark_7)" }} >{articleData.topicDesc}</div>
                                                            <div className="d-flex gap-4">
                                                                <div className="d-flex flex-column gap-2">
                                                                    <h5>AI Type</h5>
                                                                    <div style={{ border: "1px solid var(--Dark-dark_5)", borderRadius: "5px", padding: "10px 50px", background: "var(--Dark-dark_7)" }}>{articleData.AI_type}</div>
                                                                </div>
                                                                <div className="d-flex flex-column gap-2">
                                                                    <h5>Language</h5>
                                                                    <div style={{ border: "1px solid var(--Dark-dark_5)", borderRadius: "5px", padding: "10px 50px", background: "var(--Dark-dark_7)" }}>{articleData.lan}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {articleData.seoOptimizedData && (
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header className="">SEO Optimization - {3 < articleData.currentStep ? "Done" : "Pending"}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className="seoOptBlogTopic addllyFormWrap list-item-table m-0 p-0">
                                                            <ListGroup horizontal={"sm"} className="">
                                                                <ListGroup.Item className="list-group-header listGroup">Headline <p className="ms-2">(You can edit the Headline)</p></ListGroup.Item>
                                                                <ListGroup.Item className="list-group-header listGroup">Intent</ListGroup.Item>
                                                                <ListGroup.Item className="list-group-header listGroup">Keyword <p className="ms-2">(You can edit the Keyword)</p></ListGroup.Item>
                                                                <ListGroup.Item className="list-group-header listGroupAction"></ListGroup.Item>
                                                            </ListGroup>
                                                            {articleData && articleData.seoOptimizedData && articleData.seoOptimizedData?.length > 0 ? (
                                                                <React.Fragment>
                                                                    {articleData.seoOptimizedData && articleData.seoOptimizedData?.map((item, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {item.headline && (
                                                                                <ListGroup key={index} horizontal={"sm"} className="">
                                                                                    <ListGroup.Item className="listGroup">
                                                                                        <textarea name="headline" className="w-100 list-item-textarea" value={item.headline} disabled />
                                                                                    </ListGroup.Item>
                                                                                    <ListGroup.Item className="listGroup justify-content-center text-center">
                                                                                        <div className="w-100 h-100" style={{ background: 'var(--Dark-dark_7)', border: '1px solid var(--Dark-dark_6)', borderRadius: '8px' }}>
                                                                                            <div className="mb-2 mt-4">
                                                                                                {(item.intent?.toLowerCase()?.indexOf("transactional") !== -1 || item.intent?.toLowerCase()?.indexOf("transaksional") !== -1 || item.intent?.toLowerCase()?.indexOf("交易性的") !== -1 || item.intent?.toLowerCase()?.indexOf("การทำธุรกรรม") !== -1) ? (
                                                                                                    "Transactional"
                                                                                                ) : (item.intent?.toLowerCase()?.indexOf("commercial") !== -1 || item.intent?.toLowerCase()?.indexOf("komersial") !== -1 || item.intent?.toLowerCase()?.indexOf("商业的") !== -1 || item.intent?.toLowerCase()?.indexOf("ทางการค้า") !== -1) ? (
                                                                                                    "Commercial Investigation"
                                                                                                ) : (
                                                                                                    "Informative"
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </ListGroup.Item>
                                                                                    <ListGroup.Item className="listGroup">
                                                                                        <textarea name="keyword" className="w-100 list-item-textarea" value={item.keyword} disabled />
                                                                                    </ListGroup.Item>
                                                                                    <ListGroup.Item className="justify-content-center listGroupAction align-items-center">
                                                                                        <label className="checkmarkContainer">
                                                                                            <input type="checkbox" checked={articleData.headline &&
                                                                                                articleData.headline === item.headline ? true : false}
                                                                                            />
                                                                                            <span className="checkmark"></span>
                                                                                        </label>
                                                                                    </ListGroup.Item>
                                                                                </ListGroup>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </React.Fragment>
                                                            ) : (
                                                                <div className="d-flex align-items-center justify-content-center" style={{ height: "230px" }}>
                                                                    <h5 className="mb-0 fw-normal text-center">
                                                                        Headline, Intent and Keyword are not available. <br />
                                                                        Change Topic and keyword and Regenerate Headline and Keywords.
                                                                    </h5>
                                                                </div>
                                                            )}
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {articleData.seoOutlines && (
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header className="">Select Outlines - {4 < articleData.currentStep ? "Done" : "Pending"}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {(
                                                            articleData.seoOutlines && 
                                                            Object.keys(articleData.seoOutlines)?.length > 0
                                                        ) && Object.keys(articleData.seoOutlines)?.filter((item) => item).map((item, i) => (
                                                            <ul className="list-unstyled setOfOutline" key={i}>
                                                                <li key={i}>
                                                                    <div style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                                                                        <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                                                <h4 className="fw-normal">Outline Set {i + 1}</h4>
                                                                            </div>
                                                                        </div>
                                                                        {articleData.seoOutlines[item] && articleData.seoOutlines[item]?.map((it, ind) => (
                                                                            <div key={ind} className="checkListItem py-2">
                                                                                <div className="d-flex align-items-center w-100">
                                                                                    <Form.Check type="checkbox" className="custom-checkbox"
                                                                                        checked={articleData.allSelectedOutline ? (articleData.allSelectedOutline?.find((item1) => (item1 === it)) ? true : false) : false}
                                                                                    />
                                                                                    <input className="ms-3 addedOutline w-100" value={it} placeholder="Type here outline" type="text" disabled />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                        </Accordion>
                                    </Col>
                                </Row>
                            )}
                        <Row>
                            <Col xl={12}>
                                <div className="smartWriterTab">
                                    <Tabs defaultActiveKey={"article"} className="mb-3 mt-4">
                                        <Tab eventKey="article" title={<span className="active-tab-article"> Article</span>}>
                                            <Row>
                                                <Col lg={8} md={8}>
                                                    <div className="position-relative form-group" style={{ minHeight: "100vh" }}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <button type="button" className="content-view-btn" onClick={() => setToggle(!toggle)}>
                                                                {toggle ? <span>Go back to content</span> : <span>Go to HTML View</span>}
                                                            </button>
                                                            {!toggle ? (
                                                                <button type="button" className="images-view-btn" onClick={() => handleViewAiImagesById('Short Article Images')}>
                                                                    Media Library
                                                                </button>
                                                            ) : null}
                                                        </div>
                                                        {toggle ? (
                                                            <div className="html-content" style={{ height: "calc(100% - 32px)" }}>
                                                                <HtmlViewer value={articleData?.finalArticle} />
                                                            </div>
                                                        ) : (
                                                            <Editor
                                                                initialValue={articleData?.finalArticle}
                                                                apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                                                                init={editorInitObj} disabled
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                                {articleData.SEO_score_response && articleData.SEO_score_response.data && (
                                                    <SeoScore contentScore={articleData.SEO_score_response.data} />
                                                )}
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="facebook" title={<span className="d-flex align-items-center gap-2"><FaFacebookF /> Facebook Post</span>}>
                                            <Row className="mt-5">
                                                <Col sm={articleData?.facebook_post ? 8 : 12}>
                                                    <div className="socialPostEditor">
                                                        <div className="position-relative">
                                                            <div className="custom-textarea-editor">
                                                                <div className="textarea-header">Facebook Post</div>
                                                                <textarea
                                                                    className="w-100 outlineTextarea textarea-content"
                                                                    value={articleData?.facebook_post}
                                                                    style={{ height: "40vh" }} disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {articleData?.facebook_post && (
                                                    <Col sm={4} className="pb-2">
                                                        <FacebookPostPreview
                                                            post={articleData?.facebook_post} preview_img={articleData?.facebook_post_img} col={12} isUploadImage={true}
                                                            setUploadFileModal={() => handleViewAiImagesById('Facebook Post')}
                                                        />
                                                        {console.log(articleData.facebook_post_img)}
                                                    </Col>
                                                )}
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="instagram" title={<span className="d-flex align-items-center gap-2"><FaInstagram /> Instagram Post</span>}>
                                            <Row className="mt-5">
                                                <Col sm={articleData?.instagram_post ? 8 : 12}>
                                                    <div className="socialPostEditor">
                                                        <div className="position-relative">
                                                            <div className="custom-textarea-editor">
                                                                <div className="textarea-header">Instagram Post</div>
                                                                <textarea
                                                                    className="w-100 outlineTextarea textarea-content"
                                                                    value={articleData?.instagram_post}
                                                                    style={{ height: "40vh" }} disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {articleData?.instagram_post && (
                                                    <Col sm={4} className="pb-2">
                                                        <InstagramPostPreview
                                                            post={articleData?.instagram_post} preview_img={articleData?.instagram_post_img} col={12} isUploadImage={true}
                                                            setUploadFileModal={() => handleViewAiImagesById('Instagram Post')}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Tab>
                                        {/* <Tab eventKey="Infographics" title={<span className="d-flex align-items-center gap-2"><FaXTwitter />Infographics</span>}>
                                            <Row className="mt-5">
                                                <Col sm = {12}>
                                                    <div className="d-flex flex-wrap gap-3 justify-content-center">
                                                        {articleData && articleData.editedAllImages?.length > 0 ? (
                                                            articleData.editedAllImages.map((link, index) => (
                                                                <img
                                                                    key={index}
                                                                    src={link}
                                                                    alt={`Infographic ${index + 1}`}
                                                                    className="img-fluid"
                                                                    style={{ maxWidth: "300px", borderRadius: "8px" }}
                                                                />
                                                            ))
                                                        ) : (
                                                            <p>No infographics available.</p>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab> */}
                                        <Tab
                                            eventKey="Infographics"
                                            title={
                                                <span className="d-flex align-items-center gap-2">
                                                    <FaXTwitter />
                                                    Infographics
                                                </span>
                                            }
                                        >
                                            <Row className="mt-5">
                                                <Col sm={12}>
                                                    <div className="d-flex flex-wrap gap-3 justify-content-center">
                                                        {articleData && articleData.editedAllImages?.length > 0 ? (
                                                            articleData.editedAllImages.map((link, index) => (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        position: "relative",
                                                                        display: "inline-block",
                                                                        maxWidth: "300px",
                                                                        borderRadius: "8px",
                                                                        overflow: "hidden",
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        const overlay = e.currentTarget.querySelector(".image-overlay");
                                                                        if (overlay) overlay.style.display = "flex";
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        const overlay = e.currentTarget.querySelector(".image-overlay");
                                                                        if (overlay) overlay.style.display = "none";
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={link}
                                                                        alt={`Infographic ${index + 1}`}
                                                                        className="img-fluid"
                                                                        style={{
                                                                            width: "100%",
                                                                            borderRadius: "8px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => handleShowModal(index)}
                                                                    />
                                                                    <div
                                                                        className="image-overlay"
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: 0,
                                                                            left: 0,
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            background: "rgba(0, 0, 0, 0.6)",
                                                                            display: "none",
                                                                            flexDirection: "column",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            gap: "10px",
                                                                            zIndex: 1,
                                                                        }}
                                                                    >
                                                                        <button
                                                                            style={{
                                                                                padding: "8px 12px",
                                                                                background: "#007bff",
                                                                                border: "none",
                                                                                borderRadius: "4px",
                                                                                color: "#fff",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); // Prevent form submission
                                                                                handleShowModal(index);
                                                                            }}
                                                                        >
                                                                            View
                                                                        </button>
                                                                        <button
                                                                            style={{
                                                                                padding: "8px 12px",
                                                                                background: "#28a745",
                                                                                border: "none",
                                                                                borderRadius: "4px",
                                                                                color: "#fff",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); // Prevent form submission
                                                                                e.stopPropagation(); // Prevent event bubbling
                                                                                handleDownload(link);
                                                                            }}
                                                                        >
                                                                            Download
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p>No infographics available.</p>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab>


                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>

            {showModal && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.8)",
                        zIndex: 1000,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={handleCloseModal}
                >
                    {/* Left Button */}
                    <button
                        style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "50%",
                            color: "#fff",
                            fontSize: "24px",
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent closing modal
                            handlePrevImage();
                        }}
                    >
                        &#8249; {/* Left arrow symbol */}
                    </button>

                    {/* Image */}
                    <img
                        src={articleData.editedAllImages[currentImageIndex]}
                        alt={`Infographic ${currentImageIndex + 1}`}
                        style={{
                            maxWidth: "90%",
                            maxHeight: "90%",
                            borderRadius: "8px",
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal on image click
                    />

                    {/* Right Button */}
                    <button
                        style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            background: "rgba(0, 0, 0, 0.5)",
                            border: "none",
                            borderRadius: "50%",
                            color: "#fff",
                            fontSize: "24px",
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent closing modal
                            handleNextImage();
                        }}
                    >
                        &#8250; {/* Right arrow symbol */}
                    </button>
                </div>
            )}

        </div>
    );
}

export default RysenseBlogDetails