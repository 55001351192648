import React, { useState, useEffect } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import {
  InstagramPostPreview,
  LinkedInPostPreview,
} from "../Components/PostPreview";
import { setLoader } from "../store/reducer";
import Slider from "react-slick";
import { AxiosApi } from "../utility/axios";
import MediaLibraryImagesModal from "../Components/MediaLibraryModal";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const InvestIdeaDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [postData, setPostData] = useState({});
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [postImagesModal, setPostImagesModal] = useState({ open: false });
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [finalPost, setFinalePost] = useState("");

  useEffect(() => {
    if (params?.id) {
      dispatch(setLoader(true));
      AxiosApi.get(`${apiBaseUrl}/api/post-writer/postHistory/byId/${params?.id}?role=admin&type=investIdea`)
        .then(async (res) => {
          setPostData(res.data.data);
          dispatch(setLoader(false));

          if (res.data.data && res.data.data.final_post && JSON.parse(res.data.data.final_post)?.length > 0) {
            setFinalePost(JSON.parse(res.data.data.final_post));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(setLoader(false));
        });
    }
  }, [params]);

  const handleViewAiImagesById = (subType) => {
    const obj = {
      type: "InvestIdea Post Writer",
      subType,
      articleId: params?.id ? params.id : "",
      userId: postData?.user_id ? postData.user_id : "",
    };

    setPostImagesModal({ open: true, isLoader: true });
    AxiosApi.get(`${apiBaseUrl}/api/admin/media-library-image/get-images?${new URLSearchParams(obj).toString()}`)
      .then((res) => {
        setTimeout(() => {
          setPostImagesModal({ open: true, isLoader: false, ...res.data });
        }, 1000);
      })
      .catch((error) => {
        setPostImagesModal({ open: false, isLoader: false });
        toast.error("Something went wrong, Please try again.");
      });
  };

  return (
    <div className="w-100">
      <div className="border-radius-12 bg-white AIWriterBlog">
        <div className="AIWriterBlogHead">
          <div className="AIWriterBlogHeadTop">
            <h3>InvestIdea - {postData.post_type || "LinkedIn"} Post</h3>
          </div>
        </div>
        <div className="addllyFormWrap">
          <form>
            <Row>
              <Col sm="12" md="12">
                <div className="form-group mb-4">
                  <label>Discription or What would like to focus on?</label>
                  <textarea
                    name="topic"
                    type="text"
                    rows="3"
                    defaultValue={postData?.topic}
                    placeholder={
                      postData?.post_type === "Instagram"
                        ? "Example: First time trying..."
                        : "Example: Best way to get the most out of..."
                    }
                    className={`addllyForm-control`}
                    style={{ borderRadius: "25px" }}
                    disabled
                  />
                </div>
              </Col>
              {postData?.insert_link ? (
                <Col sm="12" md="6">
                  <div className="form-group mb-4">
                    <label>Need a post crafted around your website link?</label>
                    <input
                      name="link"
                      type="text"
                      placeholder="Insert your link here and let's transform it into a compelling social media post!"
                      defaultValue={postData?.insert_link}
                      className={`addllyForm-control`}
                      disabled
                    />
                  </div>
                </Col>
              ) : postData?.pdf_link ? (
                <Col sm="12" md="6">
                  <div className="form-group mb-4">
                    <label>Need a post crafted using PDF?</label>
                    <input
                      name="link"
                      type="text"
                      placeholder="Insert your link here and let's transform it into a compelling social media post!"
                      defaultValue={postData?.pdf_link}
                      className={`addllyForm-control`}
                      disabled
                    />
                  </div>
                </Col>
              ) : null}
              <Col sm="12" md="6">
                <div className="form-group mb-4">
                  <label>Type</label>
                  <input
                    name="keyword"
                    type="text"
                    placeholder="Example: Google"
                    className={`addllyForm-control`}
                    disabled
                    defaultValue={postData?.type}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="smartWriterTab">
                  <Tabs defaultActiveKey={"generatedPosts"} className="mb-3">
                    {postData && postData.sets_of_post && postData.sets_of_post !== "" && (
                      <Tab eventKey="generatedPosts" title="Generated Posts">
                        <Row className="mt-4">
                          {postData.sets_of_post &&
                            JSON.parse(postData.sets_of_post)?.map((item, index) => (
                              <Col sm={12} md={6} lg={4} key={index}>
                                <div className={`media-post-content ${postData?.final_post === item ? "active" : ""}`}>
                                  <pre>{item}</pre>
                                </div>
                              </Col>
                            ))}
                        </Row>
                      </Tab>
                    )}
                    {postData && postData.final_post && postData.final_post !== "" && (
                      <Tab eventKey="finalPost" title="Final Post and Preview">
                        <Row className="mt-4">
                          <Col sm={postData?.final_post ? 8 : 12}>
                            <div className="socialPostEditor">
                              <textarea
                                className="w-100 outlineTextarea"
                                value={finalPost}
                                style={{ height: "60vh" }}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col sm={4}>
                            {postData.post_type === "Instagram" ? (
                              <InstagramPostPreview
                                post={finalPost}
                                col={12}
                                isUploadImage={true}
                                setUploadFileModal={() => handleViewAiImagesById(`${postData.post_type} Post`)}
                                preview_img={
                                  postData?.post_image && JSON.parse(postData?.post_image)?.data
                                    ? JSON.parse(postData.post_image).data
                                    : ""
                                }
                              />
                            ) : (
                              <LinkedInPostPreview
                                post={finalPost}
                                col={12}
                                isUploadImage={true}
                                setUploadFileModal={() => handleViewAiImagesById(`${postData.post_type} Post`)}
                                preview_img={
                                  postData?.post_image && JSON.parse(postData?.post_image)?.data
                                    ? JSON.parse(postData.post_image).data
                                    : ""
                                }
                              />
                            )}
                          </Col>
                        </Row>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>

      {postImagesModal && postImagesModal.open && (
        <MediaLibraryImagesModal postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} />
      )}
    </div>
  );
};

export default InvestIdeaDetails;
