import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Dropdown, Modal } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineEye, AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-hot-toast";
import Swal from "sweetalert";

import { setLoader } from "../store/reducer";
import FacebookLogo from "../Assets/Images/Social_media/facebook-logo.png";
import InstagramLogo from "../Assets/Images/Social_media/instagram-logo.png";
import LinkedInLogo from "../Assets/Images/Social_media/linkedIn-logo.png";
import TwitterLogo from "../Assets/Images/Social_media/twitter-logo.png";
import TablePaginate from "../Components/TablePaginate";
import { MdOutlineRefresh } from "react-icons/md";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";


const RysenseBlogHistory = () => {
    const dispatch = useDispatch();
    const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
    const loginDetails = useSelector(({ loginDetails }) => loginDetails);
    const [searchTxt, setSearchTxt] = useState("");
    const [logsLoader, setLogsLoader] = useState(false);
    const [rysenseHistory, setRysenseHistory] = useState({ data: [], length: 0 });
    const [tableInfo, setTableInfo] = useState({ limit: 10, offset: 0 });

    const filterData = rysenseHistory && rysenseHistory.length > 0 && rysenseHistory.data.filter((item) => (item?.first_name || "").toLocaleLowerCase().search((searchTxt || "").toLocaleLowerCase()) >= 0 );
    const handleChangePageLoadData = (limit, offset) => {
        if (offset >= 0) {
            setTableInfo((prev) => ({ ...prev, limit, offset }));
            handleGetInvetIdeaHistory(limit, offset, searchTxt);
        }
    };

    useEffect(() => {
        handleGetInvetIdeaHistory(tableInfo.limit, tableInfo.offset);
    }, []);

    const handleGetInvetIdeaHistory = (limit, offset, search = "") => {
        setRysenseHistory({ data: [], length: 0 });
        dispatch(setLoader(true));
        const queryString = new URLSearchParams({ limit, offset }).toString();
        AxiosApi.get(`${apiBaseUrl}/api/article/history-or-details/byType/get-all-rysense-history?${queryString}`)
            .then((res) => {
                setRysenseHistory(res.data);
                dispatch(setLoader(false));
            })
            .catch((error) => {
                toast.error("Something went wrong.");
                dispatch(setLoader(false));
            });
    }

    const handleDisplayError = (item) => {
        try {
            const error = item.error ? JSON.parse(item.error) : {};
            if (error.code === "Timeout") {
                return "System is overloaded. Please try again in some time.";
            } else {
                if (error.message.indexOf("Azure") !== -1 || error.message.indexOf("filtered") !== -1) {
                    return "OpenAI failed to generate the article due to filtering, Please rephrase or generate article for a different topic.";
                }
                return error.message;
            }
        } catch (error) {
            return item.error;
        }
    };
    return (
        <React.Fragment>
            <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">Happy Dot Blog History ( {rysenseHistory.length || 0} )</h4>
                </div>
                <div className="res-table">
                    {filterData.length > 0 ? (
                        <table className="custom-table table table-hover mt-20 mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Topic</th>
                                    <th scope="col">User Name</th>
                                    <th scope="col">Last modified</th>
                                    <th scope="col">status</th>
                                    <th scope="col">Language</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterData?.map((item, ind) => (
                                    <tr
                                        className="cursor-pointer"
                                        key={ind}
                                        onClick={() => window.open(`/happydot-blog/${item.id}`, "_blank")}
                                    >
                                        <td>{item?.id}</td>
                                        <td>
                                            <h6
                                                className="text-width"
                                                style={{ maxWidth: "240px" }}
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content={item?.meta_title || "-"}
                                                data-tooltip-place="bottom"
                                            >
                                                {item?.meta_title || "-"}
                                            </h6>
                                            <p className="text-width" style={{ maxWidth: "240px" }}>
                                                {item?.blog_flow || "-"}
                                            </p>
                                        </td>
                                        <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                                        <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                                        <td>
                                            <span
                                                variant="success"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-place="bottom"
                                                data-tooltip-content={`
                          ${item?.status.charAt(0).toUpperCase() + item?.status.slice(1)}
                          ${item?.status === "error" ? `${handleDisplayError(item)}` : ""}
                        `}
                                            >
                                                {item?.status === "error" ? (
                                                    <AiFillCloseCircle size={18} color="red" />
                                                ) : item?.status === "notAcceptable" ? (
                                                    <AiFillCloseCircle size={18} color="#EF4444" />
                                                ) : item?.status === "completed" ? (
                                                    <AiFillCheckCircle size={18} color="green" />
                                                ) : (
                                                    item?.status === "pending" && <AiFillClockCircle size={18} color="#e7e721" />
                                                )}
                                            </span>
                                        </td>
                                        <td>{item?.lan}</td>
                                        <td>
                                            <Dropdown align="end" className="actionDropdown" onClick={(e) => e.stopPropagation()}>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <BsThreeDots />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item target="_blank" href={`/happydot-blog/${item.id}`} rel="noreferrer">
                                                        View <AiOutlineEye />
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <NoDataAvailable />
                    )}
                </div>
                <TablePaginate length={rysenseHistory.length} handlePageChange={handleChangePageLoadData} />
            </div>
        </React.Fragment>
    )
}

export default RysenseBlogHistory