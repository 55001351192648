import React, { useState } from "react";
import HeaderMenu from "./HeaderMenu";
import SidebarMenu from "./SidebarMenu";

const HeaderSidebar = ({ children, refundReqNo }) => {
  const [toggle, setToggle] = useState(true);
  return (
    <div className="mainContentWrapper position-relative">
      <div className={toggle ? "close" : "open"}>
        <HeaderMenu toggle={toggle} setToggle={setToggle} />
        {!toggle && <div onClick={() => setToggle(!toggle)} onKeyDown={() => setToggle(!toggle)} className="overlaySidebar"></div>}
        <div className="d-flex sidebarContent">
          <SidebarMenu refundReqNo={refundReqNo} />
          <div className="contentWrapper w-100">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSidebar;
